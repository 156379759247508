import React, { FunctionComponent, useState } from 'react';
import classNames from 'classnames';
import { fireAuth } from '../firebaseConf';

const LogoutForm: FunctionComponent = () => {
  const [formBusy, setFormBusy] = useState(false);
  const [formError, setFormError] = useState('');

  const logOut = () => {
    setFormBusy(true);
    fireAuth
      .signOut()
      .then(() => {
        setFormBusy(false);
      })
      .catch((error: Error) => {
        setFormError(error.message);
        setFormBusy(false);
      });
  };

  return (
    <>
      <h1 className="title has-text-black">Logout</h1>
      <div className="field">{formError ? <p className="has-text-danger">{formError}</p> : null}</div>
      <div className="field">
        <button
          type="button"
          className={classNames({
            'button is-link is-fullwidth': true,
            'is-loading': formBusy,
          })}
          onClick={logOut}
        >
          Log out
        </button>
      </div>
    </>
  );
};

export default LogoutForm;
