import React, { FunctionComponent } from 'react';
import Layout from '../layouts/admin-index';
import LogInOrOut from '../components/LogInOrOut';

const Auth: FunctionComponent = () => {
  return (
    <Layout>
      <section className="hero is-fullheight">
        <div className="hero-body columns is-centered">
          <div className="column is-4 card is-rounded">
            <div className="card-content">
              <LogInOrOut />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Auth;
