import React, { FunctionComponent, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { navigate } from 'gatsby';
import { fireAuth } from '../firebaseConf';

interface LoginForm {
  email: string;
  password: string;
}

const LoginForm: FunctionComponent = () => {
  const [formBusy, setFormBusy] = useState(false);
  const [formError, setFormError] = useState('');

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email()
        .required(),
      password: Yup.string().required(),
    }),
    onSubmit: (values: LoginForm) => {
      setFormBusy(true);
      fireAuth
        .signInWithEmailAndPassword(values.email, values.password)
        .then(() => {
          setFormBusy(false);
          navigate('/');
        })
        .catch((error: Error) => {
          setFormError(error.message);
          setFormBusy(false);
        });
    },
  });

  return (
    <>
      <h1 className="title has-text-black">Login</h1>
      <form id="login-form" onSubmit={formik.handleSubmit} onChange={() => setFormError('')}>
        <div className="field">{formError ? <p className="has-text-danger">{formError}</p> : null}</div>
        <div className="field">
          <label htmlFor="email" className="label">
            Email
            <div className="control">
              <input
                id="email"
                name="email"
                type="text"
                className="input"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
            </div>
          </label>
          {formik.touched.email && formik.errors.email ? <p className="help is-danger">{formik.errors.email}</p> : null}
        </div>
        <div className="field">
          <label htmlFor="password" className="label">
            Password
            <div className="control">
              <input
                id="password"
                name="password"
                type="password"
                className="input"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
            </div>
          </label>
          {formik.touched.password && formik.errors.password ? <p className="help is-danger">{formik.errors.password}</p> : null}
        </div>
        <div className="field">
          <button
            type="submit"
            form="login-form"
            className={classNames({
              'button is-link is-fullwidth': true,
              'is-loading': formBusy,
            })}
          >
            Log in
          </button>
        </div>
      </form>
    </>
  );
};

export default LoginForm;
