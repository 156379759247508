import React, { FunctionComponent } from 'react';
import { useAuthState } from '../state/AuthContext';
import LogoutForm from './LogoutForm';
import LoginForm from './LoginForm';

const LogInOrOut: FunctionComponent = () => {
  const { loggedIn } = useAuthState();

  return <>{loggedIn ? <LogoutForm /> : <LoginForm />}</>;
};

export default LogInOrOut;
